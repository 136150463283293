{
  "name": "@vercel/speed-insights",
  "version": "1.2.0",
  "description": "Speed Insights is a tool for measuring web performance and providing suggestions for improvement.",
  "keywords": [
    "speed-insights",
    "vercel"
  ],
  "repository": {
    "url": "github:vercel/speed-insights",
    "directory": "packages/web"
  },
  "license": "Apache-2.0",
  "exports": {
    "./package.json": "./package.json",
    ".": {
      "browser": "./dist/index.mjs",
      "import": "./dist/index.mjs",
      "require": "./dist/index.js"
    },
    "./astro": {
      "import": "./dist/astro/component.ts"
    },
    "./next": {
      "browser": "./dist/next/index.mjs",
      "import": "./dist/next/index.mjs",
      "require": "./dist/next/index.js"
    },
    "./nuxt": {
      "browser": "./dist/nuxt/index.mjs",
      "import": "./dist/nuxt/index.mjs",
      "require": "./dist/nuxt/index.js"
    },
    "./react": {
      "browser": "./dist/react/index.mjs",
      "import": "./dist/react/index.mjs",
      "require": "./dist/react/index.js"
    },
    "./remix": {
      "browser": "./dist/remix/index.mjs",
      "import": "./dist/remix/index.mjs",
      "require": "./dist/remix/index.js"
    },
    "./sveltekit": {
      "svelte": "./dist/sveltekit/index.mjs",
      "types": "./dist/sveltekit/index.d.ts"
    },
    "./vue": {
      "browser": "./dist/vue/index.mjs",
      "import": "./dist/vue/index.mjs",
      "require": "./dist/vue/index.js"
    }
  },
  "main": "./dist/index.js",
  "types": "./dist/index.d.ts",
  "typesVersions": {
    "*": {
      "*": [
        "dist/index.d.ts"
      ],
      "react": [
        "dist/react/index.d.ts"
      ],
      "next": [
        "dist/next/index.d.ts"
      ],
      "nuxt": [
        "dist/nuxt/index.d.ts"
      ],
      "remix": [
        "dist/remix/index.d.ts"
      ],
      "sveltekit": [
        "dist/sveltekit/index.d.ts"
      ],
      "vue": [
        "dist/vue/index.d.ts"
      ]
    }
  },
  "scripts": {
    "build": "tsup && pnpm copy-astro",
    "copy-astro": "cp -R src/astro dist/",
    "dev": "pnpm copy-astro && tsup --watch",
    "postinstall": "node scripts/postinstall.mjs",
    "lint": "eslint .",
    "lint-fix": "eslint . --fix",
    "test": "vitest",
    "type-check": "tsc --noEmit"
  },
  "devDependencies": {
    "@remix-run/react": "^2.14.0",
    "@sveltejs/kit": "^2.8.1",
    "@swc/core": "^1.9.2",
    "@testing-library/jest-dom": "^6.6.3",
    "@testing-library/react": "^16.0.1",
    "@types/node": "^22.9.1",
    "@types/react": "^18.3.12",
    "copyfiles": "^2.4.1",
    "jsdom": "^25.0.1",
    "next": "^14.0.4",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "svelte": "^5.2.7",
    "tsup": "8.3.5",
    "vitest": "^2.1.5",
    "vue": "^3.5.13",
    "vue-router": "^4.4.5"
  },
  "peerDependencies": {
    "@sveltejs/kit": "^1 || ^2",
    "next": ">= 13",
    "react": "^18 || ^19 || ^19.0.0-rc",
    "svelte": ">= 4",
    "vue": "^3",
    "vue-router": "^4"
  },
  "peerDependenciesMeta": {
    "@sveltejs/kit": {
      "optional": true
    },
    "next": {
      "optional": true
    },
    "react": {
      "optional": true
    },
    "svelte": {
      "optional": true
    },
    "vue": {
      "optional": true
    },
    "vue-router": {
      "optional": true
    }
  }
}
